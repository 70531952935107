// ImportMapper.scss
// Variables
$color-green-50: #f0fdf4;
$color-green-200: #bbf7d0;
$color-green-500: #22c55e;
$color-gray-50: #f9fafb;
$color-gray-200: #e5e7eb;
$color-gray-300: #d1d5db;
$color-gray-400: #9ca3af;
$color-gray-600: #4b5563;

// Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
}

@mixin card-base {
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.import-link-button {
  &:hover {
    background-color: #f2f0ef !important;
  }
}

// Main Container
.import-mapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem;
}

// Cards
.import-card {
  @include card-base;

  &__header {
    padding: 1.25rem 1.5rem;
    border-bottom: 1px solid $color-gray-200;
  }

  &__title {
    font-size: 1.25rem;
    font-weight: 600;
    color: black;
    margin: 0;
  }

  &__content {
    padding: 1.5rem;
  }
}

// Field Mapping
.field-mapping {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    padding: 0.5rem 1rem;
    background: $color-gray-50;
    border-radius: 0.5rem 0.5rem 0 0;
  }

  &__header-cell {
    font-weight: 500;
    color: $color-gray-600;

    &--center {
      text-align: center;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    align-items: center;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid;
    transition: all 0.2s ease;

    &--mapped {
      background: $color-green-50;
      border-color: $color-green-200;
    }

    &--unmapped {
      background: $color-gray-50;
      border-color: $color-gray-200;
    }

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    }
  }
}

// Field Components
.field {
  &__source {
    @include flex-center;
    gap: 0.5rem;

    &-icon {
      flex-shrink: 0;
      width: 1.25rem;
      height: 1.25rem;

      &--mapped {
        color: $color-green-500;
      }

      &--unmapped {
        color: $color-gray-400;
      }
    }

    &-label {
      font-weight: 500;
    }
  }

  &__mapping {
    @include flex-center;
    justify-content: center;
    gap: 0.5rem;

    &-line {
      height: 0.5rem;
      width: 4rem;
      border-radius: 0.25rem;

      &--mapped {
        background: $color-green-500;
      }

      &--unmapped {
        background: $color-gray-300;
      }
    }

    &-arrow {
      width: 1.25rem;
      height: 1.25rem;

      &--mapped {
        color: $color-green-500;
      }

      &--unmapped {
        color: $color-gray-400;
      }
    }
  }

  &__target {
    select {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid $color-gray-200;
      border-radius: 0.375rem;
      background: white;
      transition: border-color 0.2s ease;

      &:hover {
        border-color: $color-gray-400;
      }

      &:focus {
        outline: none;
        border-color: $color-green-500;
        box-shadow: 0 0 0 2px rgba($color-green-500, 0.1);
      }
    }
  }
}

// Preview Table
.preview-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  th,
  td {
    padding: 0.5rem 1rem;
    text-align: left;
    border-bottom: 1px solid $color-gray-200;

    &:first-child {
      padding-left: 1.5rem;
    }

    &:last-child {
      padding-right: 1.5rem;
    }
  }

  th {
    font-weight: 500;
    color: $color-gray-600;
    background: $color-gray-50;
  }

  tr {
    &:last-child {
      td {
        border-bottom: none;
      }
    }

    &:hover {
      td {
        background: $color-gray-50;
      }
    }
  }
}
