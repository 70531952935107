.invoicing-background {
  background: #e5e5e7;
  height: 80vh;
  overflow-y: auto;
}
.invoicing-settings-span {
  border: 1px solid #e5e5e7;
  padding: 5px;
  color: #6f7272;
  border-radius: 2px;
  cursor: pointer;
}
.invoicing-menu {
  position: absolute;
  top: 30px;
  background-color: #fff;
  padding: 6px 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  border-radius: 2px;
  width: 145px;

  &-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    // &-edit-view {
    // }
    // &-edit-view-selected {
    // }
    &-download {
      background-color: #a7e4fa;
      color: #1778f2;
      border: none;
      outline: none;
      padding: 5px 10px;
      border-radius: 4px;
      margin-right: 10px;
      cursor: pointer;
      font-weight: 500;
    }
    &-send {
      background-color: #bfe9d7;
      color: #199661;
      border: none;
      outline: none;
      padding: 5px 10px;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 500;
    }
  }

  label {
    display: inline-block;
    padding: 6px;
  }
  li {
    display: block;
  }
  input {
    display: inline-block;
  }
}
.invoicing-form {
  background-color: white;
  // width: 58vw;
  height: auto;
  min-height: 30vh;
  margin: 10px auto;
  margin-bottom: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 24px;
  width: 210mm - 48px;
  // min-height: 297mm;

  label {
    font-size: 14px;
    margin: 15px 0 8px 0;
  }

  &-delete-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
  }

  &-view {
    background-color: white;
    height: auto;
    min-height: 30vh;
    margin: 10px auto;
    margin-bottom: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    width: 794px;

    &-container {
      padding: 24px;
    }
    &-info {
      font-size: 14px;
      font-weight: 300;
    }
    &-notes {
      font-size: 14px;
      font-weight: 300;
      margin-top: 20px;
    }
    &-heading {
      margin: 10px 0;
      font-size: 18px;
      margin-top: 24px;
    }
    &-powered {
      color: #383a39;
      text-align: center;
      margin-top: 35px;
      font-weight: 500;

      &-span {
        transform: translateY(-6px);
        display: inline-block;
      }
    }
    &-table {
      &-container {
        margin-top: 20px;
        margin-bottom: 10px;
        border-bottom: 1px solid #e5e5e7;
        padding-bottom: 10px;
      }
      thead {
        background-color: #a7e4fa;
        color: black !important;
        text-transform: capitalize;
        font-size: 14px;
        padding: 6px;
      }
      th {
        color: black !important;
        text-transform: capitalize;
        font-size: 16px;
        padding: 6px;
        font-weight: 500;
      }
      td {
        padding: 6px;
        font-size: 14px;
      }
    }
    &-adjustments {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 15px;

      &-key {
        color: #6f7272;
      }
      &-value {
        color: black;
      }
    }
    &-total {
      display: flex;
      justify-content: flex-end;
      color: #6f7272;
      font-size: 18px;
      font-weight: 500;

      &-value {
        color: black;
      }
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
  }
  &-flex {
    display: flex;
  }

  &-input-invisible {
    border: none !important;

    &:hover {
      border: 1px dashed #c5c5d3 !important;
    }
  }
}
/*-----checkbox invoice-slider -----*/
/*invoice-Switch*/
.general-heading {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: right;
  letter-spacing: -0.03em;
  color: #0c0d0d;
}
/* The invoice-switch - the box around the invoice-slider */
.invoice-switch {
  position: relative;
  display: inline-block;
  width: 28px; /*52 x 12*/
  height: 16px;
  transform: translateY(-3px);
}

/* Hide default HTML checkbox */
.invoice-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The invoice-slider */
.invoice-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #baccdd;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 28px;
}

.invoice-slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 1px;
  bottom: 1px;
  background-color: #a5a5a6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  background: #ffffff;
  /* Light/shadow-sm */

  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
}

input:checked + .invoice-slider {
  background-color: #a1dfc5;
}
input:checked + .invoice-slider:before {
  background-color: #3aab7b;
}

input:focus + .invoice-slider {
  box-shadow: 0 0 1px #a1dfc5;
}

input:checked + .invoice-slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(12px);
}

/* Rounded invoice-sliders */
.invoice-slider.round {
  border-radius: 28px;
}

.invoice-slider.round:before {
  border-radius: 50%;
}
.invoice-margin-y-20 {
  margin: 20px 0;
}
