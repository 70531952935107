$color-grey: #f3f3f6;

.funnel {
  padding: 0 20px;
  padding-bottom: 0;
  border-right: 3px solid $color-grey;
  // position: relative;
  &-redirect-buttons {
    margin-top: 10px;
    text-align: left;
    margin-bottom: 10px;
    span {
      border: 1px solid #c0c0e2;
      margin-right: 10px;
      padding: 2px 5px;
      border-radius: 3px;
      font-size: 10px;
      cursor: pointer;
      color: #6f7272;
    }
  }

  &-reasons {
    text-align: left;
  }

  &-chart {
    position: relative;

    img {
      text-align: left;
    }
  }
}

.h4 {
  color: $color-grey;
}

.card-funnel {
  margin-bottom: 15px;
  position: relative;
  padding-right: 0;
  height: 100%;

  &-editButton {
    border: none;
    background: transparent;
    cursor: pointer;
    outline: none;
  }
}

.funnel-heading {
  display: flex;
  margin-top: 0;
  padding-top: 20px;
  font-weight: 400;
  &:hover{
    color: #4a9876;
  }
}

.funnel-heading-sub {
  position: absolute;
  font-style: normal;
  top: 18px;
  left: 8px;
  font-weight: 500;
  font-size: 19px;
  line-height: 22px;
  // margin-right: 150px;
  margin-top: 0;
  margin-left: 15px;
  // color: #94ceb5;
  &:hover{
    color: #4a9876;
  }
}

.funnel-heading-sub-1 {
  font-style: normal;
  // margin-right: 95px; // close reason k liye use hua tha
  margin-top: -5px;
  font-weight: 500;
  color: #000000;
  margin-left: 14px;
  margin-right: 0;
  font-size: 19px;
  &:hover{
    color: #4a9876;
  }
}
.funnel-heading-sub-2 {
  font-style: normal;
  margin-right: 95px;
  margin-top: 0;
  font-weight: 500;
  margin-left: 14px;
  &:hover{
    color: #4a9876;
  }
}

.funnel-heading-sub-c {
  margin-top: 0;
  font-weight: 400;
  border: none;
  margin-right: 15px;
  position: absolute;
  right: 8px;
  top: 18px;
  &:hover{
    color: #4a9876;
  }
}

.funnel-heading-sub-followup {
  margin: 0;
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  margin-left: 16px;
  margin-bottom: 5px;
  cursor: pointer;
  text-decoration: underline;
}

.hottestFollowUp-type {
  border: none;
  font-size: 15px;
  line-height: 18px;
  margin-right: 18px;
  color: #657885;
  outline: none;
  cursor: pointer;
  background: transparent;
  padding: 5px 12px 5px 12px;
  border-radius: 9px;

  &-selected {
    color: #4a9876;
  }
}

.funnel-select {
  outline: none;
  background: white;
  color: black;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
}

.funnel-key {
  display: inline-block;

  li {
    display: flex;
    flex-direction: column;
    border-bottom: 1.5px solid $color-grey;

    &:not(:last-child) {
      margin-bottom: 22px;
    }

    &:first-child {
      color: $color-grey;
      text-transform: uppercase;
      margin-bottom: none;
    }
  }
}

#funnel {
  display: inline-block;
}

.funnel-key-1 {
  font-size: 12px;
  margin-top: 15px;

  li {
    margin-right: 10px;
  }
}

table {
  font-size: 12px;
  column-gap: 30px;

  th {
    color: #c0c0e2;
    text-transform: uppercase;
    font-size: 10px;
    text-align: left;
    width: 150px;
  }

  td {
    text-align: left;
    width: 150px;
  }
}

.funnel-amounts {
  text-align: center;
  width: 100px;
}

.selectionMenu {
  border: "none";
  box-shadow: 4px 4px 4px rgba(black, 0.4);
}
