.dropdown {
  position: relative;
  display: inline-block;
  margin-left: 1px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    top: 40px;
    right: -15px;
    width: 250px;
    background-color: white;
    font-weight: bold;
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    font-size: 14px;
    border-radius: 5px;

    a {
      font-size: 12px;
    }
  }

  li a {
    color: #000;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
  }

  li {
    margin-left: 5px;
    width: 210px;
    padding: 8px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 14px;
    font-weight: 500;
  }

  li:last-child {
    border-bottom: none;
  }

  li:hover {
    background-color: #e5e5e5;
  }

  .button {
    width: 0px;
    height: 0px;
    background-color: #ff3232;
    padding: 0;
    // border-radius: 5px;
    font-weight: bold;
    color: white;

    &:hover {
      cursor: pointer;
    }
  }

  .button:before {
    content: "";
    position: absolute;
    // width: 27px;
    // height: 26px;
    // border: 6px solid;
    border-color: white transparent transparent transparent;
    right: 14px;
    top: 3px;
  }
}

.dropdownDashboard {
  position: relative;
  display: inline-block;
  margin-left: 1px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    top: 25px;
    right: 0px;
    width: 250px;
    background-color: white;
    font-weight: bold;
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    font-size: 12px;
    border-radius: 5px;

    a {
      font-size: 12px;
    }
  }

  li a {
    color: #000;
    text-decoration: none;
    font-size: 12px;
    padding: 8px;
  }

  li {
    margin-left: 0px;
    width: 150px;
    padding: 8px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 13px;
    text-align: left;

    div {
      padding: 2px 2px;
      position: relative;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #0c0d0d;
      &:not(:last-child) {
        border-bottom: 1px solid #e5e5e5;
        cursor: pointer;
      }
      &:last-child {
        cursor: pointer;
      }
    }
  }

  li:last-child {
    border-bottom: none;
  }

  // li:hover {
  //   background-color: #e5e5e5;
  //   // color: white;
  // }

  .button {
    width: 55px;
    height: 18px;
    background-color: transparent;
    padding: 0;
    // border-radius: 5px;
    font-weight: bold;
    color: white;

    &:hover {
      cursor: pointer;
    }
  }

  .button:before {
    content: "";
    position: absolute;
    width: 27px;
    height: 26px;
    // border: 6px solid;
    border-color: white transparent transparent transparent;
    right: 14px;
    top: 3px;
  }
}

.dropdownTask {
  position: relative;
  display: inline-block;
  margin-left: 1px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    top: 35px;
    right: 0px;
    width: 80px;
    background-color: white;
    font-weight: bold;
    position: absolute;

    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  li a {
    color: #000;
    text-decoration: none;
  }

  li {
    padding: 8px 10px;
    border-bottom: 1px solid #e5e5e5;
  }

  li:last-child {
    border-bottom: none;
  }

  li:hover {
    background-color: #e5e5e5;
  }

  .button {
    width: 0px;
    height: 0px;
    background-color: #ff3232;
    padding: 0;
    font-weight: bold;
    color: white;
    right: 14px;
    top: 10px;
    position: absolute;

    &:hover {
      cursor: pointer;
    }
  }

  .button:before {
    // content: url('../images/Arrow.svg');
    position: absolute;
    width: 0px;
    height: 0px;
    border-color: white transparent transparent transparent;
    right: 14px;
    top: 10px;
  }
}

.dropdownAccount {
  position: relative;
  display: inline-block;
  margin-left: 1px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    top: 45px;
    right: 0px;
    width: 250px;
    background-color: white;
    font-weight: bold;
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    font-size: 12px;
    border-radius: 5px;

    a {
      font-size: 12px;
    }
  }

  li a {
    color: #000;
    text-decoration: none;
    font-size: 12px;
  }

  li {
    margin-left: 0px;
    width: 155px;
    padding: 8px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 12px;
  }

  li:last-child {
    border-bottom: none;
  }

  li:hover {
    background-color: #e5e5e5;
    // color: white;
  }

  .button {
    width: 20px;
    height: 20px;
    background-color: transparent;
    padding: 0;
    // border-radius: 5px;
    font-weight: bold;
    color: black;

    &:hover {
      cursor: pointer;
    }
  }
}

.dropdownAddLi {
  cursor: pointer;
  width: 107.5px;
  font-weight: 500;
  border-radius: 4px;
  padding: 10px !important;
  margin-left: 0 !important;
  &:hover {
    color: #4A9876;
  }
}

.dropdownActivity {
  position: relative;
  display: inline-block;
  margin-left: 1px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    top: 25px;
    left: 0px;
    width: 250px;
    background-color: white;
    font-weight: bold;
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    font-size: 12px;
    border-radius: 5px;

    a {
      font-size: 12px;
    }
  }

  li a {
    color: #000;
    text-decoration: none;
    font-size: 12px;
  }

  li {
    margin-left: 0px;
    width: 234px;
    padding: 8px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 13px;
  }

  li:last-child {
    border-bottom: none;
  }

  li:hover {
    background-color: #e5e5e5;
    // color: white;
  }

  .button {
    width: auto;
    height: 20px;
    background-color: transparent;
    padding: 0;
    // border-radius: 5px;
    font-weight: bold;
    color: black;

    &:hover {
      cursor: pointer;
    }
  }
}

.dropdownNotification {
  position: relative;
  display: inline-block;
  margin-left: 1px;

  &-heading {
    margin: 5px 0;
    font-weight: 500;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    top: 25px;
    right: 0px;
    background-color: white;
    font-weight: bold;
    position: absolute;
    padding: 18px;
    width: 40vw;
    height: 80vh;
    overflow-y: auto;
    border-radius: 4px;

    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    @media only screen and (max-width: 1194px) {
      width: 60vw;
    }

    @media only screen and (max-width: 600px) {
      width: 80vw;
    }
  }

  li a {
    color: #000;
    text-decoration: none;
  }

  li {
    padding: 8px 10px;
    border-bottom: 1px solid #e5e5e5;
    font-weight: 400;
    min-height: 40px;
    height: auto;
    width: 95%;
    cursor: pointer;
    display: flex;
  }

  li:last-child {
    border-bottom: none;
  }

  li:hover {
    background-color: #f6f9f8;
  }

  .button {
    width: 0px;
    height: 0px;
    background-color: #ff3232;
    padding: 0;
    font-weight: bold;
    color: white;
    right: 0px;
    top: 10px;
    position: absolute;

    &:hover {
      cursor: pointer;
    }
  }

  .button:before {
    // content: url('../images/Arrow.svg');
    position: absolute;
    width: 0px;
    height: 0px;
    border-color: white transparent transparent transparent;
    right: 14px;
    top: 10px;
  }
}
.notifications {
  &-empty-container {
    font-weight: 400;
    padding: 30px;
    text-align: center;
    margin-top: 10%;
    img {
      height: 45px;
    }

    h2 {
      font-weight: 400;
      font-size: 18px;
    }
    p {
      font-size: 13px;
    }
  }
  &-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 1px;
  }
  &-time {
    color: #4d4d5e;
    font-weight: normal;
    font-size: 12px;
    margin-top: 5px;
  }
  &-body {
    font-size: 13px;
    font-weight: normal;
  }
  &-img {
    padding: 8px 18px 5px 8px;
  }
  &-button-wrapper {
    font-size: 14px;
    transform: translate(-17px, -26px);
  }
}

.dropdownTemplate {
  position: relative;
  display: inline-block;
  margin-left: 1px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    bottom: 35px;
    right: 0px;
    width: 250px;
    background-color: white;
    color: #000;
    font-weight: bold;
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    font-size: 12px;
    border-radius: 5px;

    a {
      font-size: 12px;
    }
  }

  li a {
    color: #000;
    text-decoration: none;
    font-size: 12px;
  }

  li {
    margin-left: 0px;
    width: 175px;
    padding: 8px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 12px;
    position: relative;
    .dropdownTemplate-options {
      display: none;
    }

    &:hover .dropdownTemplate-options {
      display: block;
    }
  }

  li:last-child {
    border-bottom: none;
  }

  li:hover {
    background-color: #f5f5f5;
    // color: white;
  }

  .button {
    width: 34px;
    height: 32px;
    background-color: transparent;
    padding: 0;
    transform: translate(-10px, 0px);
    // border-radius: 5px;
    font-weight: bold;
    color: black;

    &:hover {
      cursor: pointer;
    }

    img {
      transform: translate(10px, 5px);
    }
  }
}

.dropdownSchedule {
  position: relative;
  display: inline-block;
  margin-left: 1px;

  &-buttonSchedule {
    width: 45%;
    background: white;
    color: #4A9876;
    border-radius: 4px;
    border: 1px solid #4A9876;
    padding: 6px;
    margin: 10px auto;
    cursor: pointer;
    margin-left: 30%;
  }

  &-ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    bottom: 35px;
    right: 0px;
    width: 250px;
    background-color: white;
    color: #000;
    font-weight: 400;
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    font-size: 12px;
    border-radius: 5px;
    cursor: default;

    @media only screen and (max-width: 770px) {
      left: 0px;
    }

    a {
      font-size: 12px;
    }
  }

  li a {
    color: #000;
    text-decoration: none;
    font-size: 12px;
  }

  li {
    margin-left: 0px;
    width: auto;
    padding: 8px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 12px;
    position: relative;
    background-color: white;
  }

  li:last-child {
    border-bottom: none;
    background-color: white;
  }

  // li:hover {
  //   background-color: #e5e5e5;
  //   // color: white;
  // }

  .button {
    width: 34px;
    height: 32px;
    background-color: transparent;
    padding: 0;
    transform: translate(-10px, 0px);
    // border-radius: 5px;
    font-weight: bold;
    color: black;

    &:hover {
      cursor: pointer;
    }

    img {
      transform: translate(10px, 5px);
    }
  }
}
