.walk-through {
  &-grid {
    display: grid;
    grid-template-columns: 40% 60%;
  }
  &-sidebar {
    height: 74vh;
    overflow-y: auto;
    width: 40vw;
    border-right: 1px solid #e5e5e7;
    overflow-x: hidden;

    &-list {
      font-size: 16px;
      margin: 10px;

      &-group {
        font-weight: 700;
      }

      &-item {
        margin: 10px 0 10px 20px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
          color: #41c99d;
        }
      }
    }
  }
  &-player {
    margin: 3% auto;
    &-title {
      font-size: 20px;
      color: #41c99d;
    }
  }
}
