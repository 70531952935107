$color-grey: #dcdcef;
$color-black: #333333;
$color-purple: #a5b2bb;

.targets {
  padding: 0 20px;

  &-heading-sub {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 25px;
    margin-top: 20px;
    color: #000000;
    margin-left: -8px;
    transform: translateX(-10px);
  }
  &-progressbar {
    margin-left: 20px;
    font-size: 14px !important;
    max-width: 100% !important;
  }
  &-header {
    font-weight: 500;
    font-size: 19px;
    line-height: 19px;
    margin-bottom: 20px;
    margin-top: 30px;
    color: #000000;
    text-align: left;
    margin-left: 20px;
  }

  &-desc {
    margin-top: 16px;
    font-size: 14px;
  }
}

.targetsHeading:hover {
  color: #4a9876 !important;
}

.card {
  // border: 7px solid #f3f3f6;
  box-shadow: 0px 0px 4px rgba(13, 13, 13, 0.2);
  border-radius: 10px;
  background: #ffffff;
  position: relative;
  // box-shadow:  20px 20px 60px #d9d9d9,
  //     -20px -20px 60px #F1F1F8;
  text-align: center;
  padding: 7px 10px;
  height: 100%;
  padding-bottom: 0;
}
.card-shipment-sales-rep {
  // border: 7px solid #f3f3f6;
  box-shadow: 0px 0px 4px rgba(13, 13, 13, 0.2);
  border-radius: 10px;
  background: #ffffff;
  position: relative;
  // box-shadow:  20px 20px 60px #d9d9d9,
  //     -20px -20px 60px #F1F1F8;
  text-align: center;
  padding: 7px 10px;
  height: 100%;
  padding-bottom: 0;
}

.progressbar {
  height: 90px;
}

p {
  font-size: 12px;
}
