//Colors
$color-grey: #aeaec1;
$color-purple: #8585d1;
$color-black: #444444;

//Design
.header {
  width: 100vw;
  padding-top: 5px;
  height: 45px;
  top: 0px;
  display: flex;
  position: fixed;
  z-index: 9999999;

  background: #252a2d;

  &-row {
    padding: 0 40px;
    width: calc(100vw - 80px);
    display: grid;
    grid-template-columns: 25% 50% 25%;
    @media only screen and (max-width: 1200px) {
      width: calc(100vw - 40px);
      padding: 0 20px;
    }
    @media only screen and (max-width: 1160px) {
      width: 100%;
      padding: 0;
    }
  }

  &-img {
    margin-top: 2px;
    max-height: 43px;
  }

  &__logo {
    display: flex;
  }

  &__search {
    margin-left: 20px;
    height: 22px;
    border: 2px solid $color-grey;
    width: 150px;
    outline: none;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      // content: \002B;
      font-family: FontAwesome, Roboto;

      font-weight: normal;
      color: #586874;
      opacity: 1;
      /* Firefox */
    }
  }

  &__search-1 {
    margin-left: 10px;
    padding-left: 15px;
    height: 31px;
    border: 1px solid $color-grey;
    border-radius: 13px;
    width: 200px;
    font-size: 14px;
    outline: none;
    transform: translateY(-8px);

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      // content: \002B;
      font-family: FontAwesome, Roboto;

      font-weight: normal;
      color: #586874;
      opacity: 1;
      /* Firefox */
    }

    // &:focus{
    //     width: 150px;
    // }
  }

  &__icon {
    font-size: 20px;
  }

  &__menu {
    ul {
      margin: 0;
      padding: 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      list-style: none;
      display: inline-block;

      li {
        display: inline-block;
        color: white;
      }
    }

    .menu {
      text-decoration: none;
      color: $color-grey;
      padding: 10px;
      width: 70px;
      text-align: center;
      margin-left: 60px;

      text {
        transform: translateY(-10px);
      }

      &:hover {
        color: aquamarine;
      }

      &:not(:last-child) {
        margin-right: 50px;
      }

      &:last-child {
        margin-right: 200px;
      }
    }
  }
}

ion-icon {
  font-size: 16px;
  margin: 0;
}

.header-items {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.header-logo {
  display: flex;
  margin-top: 5px;
  margin-left: 45px;
}

.menu {
  text-decoration: none;
  color: #fff;
  padding: 10px;
  width: auto;
  text-align: center;
  margin-top: 5px;

  &:hover {
    color: #62ca9d;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.h-section {
  margin-right: 150px;
}

ul {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none;

  li {
    display: inline-block;
    font-size: 14px;
  }
}

.customer {
  float: right;
  margin-left: 35px;
  display: flex;
  margin-top: 8px;

  text-decoration: none;

  &__add {
    color: white;
    text-decoration: none;
    border-radius: 3px;
    display: inline-block;
    // transform: translate(-82px, -26px);
    // margin-right: 20px;
    transition: all 0.5s;
    background: #62ca9d;
    font-size: 16px;
    height: 32px;
    width: 130px;
    text-align: center;
    position: relative;
    font-weight: 500;
  }

  &__bell {
    text-decoration: none;
    margin-right: 20px;
    // margin-top: 15px;
    // padding-top: 20px;
    color: white;
    font-size: 20px;
    transition: all 0.1s;
    // transform: translateY(15px);
  }

  &__profile {
    text-decoration: none;
    background-color: white;
    border: none;
    color: #252a2d;
    border-radius: 50%;
    margin-top: 3px;
    transition: all 0.1s;
    font-size: 14px;
    height: 32px;
    width: 32px;
    text-align: center;
    display: inline-block;
    position: relative;

    &-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.customer-1 {
  text-decoration: none;
  &-ul {
    justify-content: flex-end;
    display: flex;
    width: 100%;
    align-items: center;
    li {
      min-width: 40px;
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
        margin-right: 25px;
      }
    }
  }
}

.nav-button {
  text-align: center;
  //   max-width: 50px;
  margin-top: 5px;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;

  a {
    transform: translateY(-10px);
  }
}

// screen responsive dropdown
.dropdownNav {
  overflow: hidden;
}

.dropdownNav .dropbtnNav {
  font-size: 14px;
  border: none;
  outline: none;
  color: white;
  padding: 6px 10px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdownNav:hover .dropbtnNav {
  background-color: #62ca9d;
}

.dropdown-contentNav {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  height: auto;
  overflow-y: auto;

  @media only screen and (max-width: 1000px) {
    max-height: 130px;
  }
}

.dropdown-contentNav a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  border-bottom: #e5e5e5;
}

.dropdown-contentNav a:hover {
  background-color: #ddd;
}

.dropdownNav:hover .dropdown-contentNav {
  display: block;
}

//navbar client
.has-dropdown {
  position: relative;
}

.has-dropdown ul {
  min-width: 100%;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: rotateY(45deg);
  transform: rotateY(45deg);
}

.has-dropdown ul li {
  display: block;
  border-bottom: 1px solid #e5e5e5;
  &:hover {
    background-color: #e5e5e5;
    border-radius: 5px;
  }
}

.has-dropdown ul li > a {
  color: black;
  padding: 10px;
  white-space: nowrap;
  display: block;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
}
.has-dropdown > ul > li > a,
.has-dropdown ul ul,
.has-dropdown > a:after {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.has-dropdown ul li > span {
  color: black;
  padding: 10px;
  white-space: nowrap;
  display: block;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
}
.has-dropdown > ul > li > span,
.has-dropdown ul ul,
.has-dropdown > span:after {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.has-dropdown:hover > ul {
  visibility: visible;
  opacity: 1;
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}

.has-dropdown li:hover > a {
  color: #62ca9d;
}
.has-dropdown li:hover > span {
  color: #62ca9d;
}

//navbar add button dropdown

//navbar client
.has-dropdown-1 {
  position: relative;
}

.has-dropdown-1 ul {
  // min-width: 100%;
  width: 150px;
  background-color: #fff;
  position: absolute;
  right: 100%;
  top: 0%;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: rotateY(45deg);
  transform: rotateY(45deg);
}

.has-dropdown-1 ul li {
  display: block;
  margin: 0;
  width: 90%;
}

.has-dropdown-1 ul li > a {
  color: black;
  // padding: 10px;
  white-space: nowrap;
  display: block;
  text-decoration: none;
  font-weight: 500;
}
.has-dropdown-1 > ul > li > a,
.has-dropdown-1 ul ul,
.has-dropdown-1 > a:after {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.has-dropdown-1:hover > ul {
  visibility: visible;
  opacity: 1;
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}

.has-dropdown-1 li:hover > a {
  color: #62ca9d;
}
@media (max-width: 1310px) {
  .header-img {
    padding-right: 0;
  }
}

.navbar-search {
  &-items {
    &:hover {
      background-color: #3b4043 !important;
    }
  }
}
.MuiAutocomplete-paper {
  background-color: #252a2d !important;
}
