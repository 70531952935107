.dashboard-loader-element1 {
  top: 16px;
  height: 16px;
  width: 35%;
}
.dashboard-loader-element2 {
  top: 36px;
  height: 50px;
  width: 75%;
}

.dashboard-loader-element3 {
  top: 58px;
  height: 14px;
  width: 100%;
}

.dashboard-loader-element4 {
  top: 68px;
  left: 0px;
  height: 14px;
  width: 80%;
}
.dashboard-loader-gradient {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #fafafa;
  background: linear-gradient(
    to right,
    #fafafa 15%,
    #cdcdcd 20%,
    #fafafa 30%,
    #fafafa 15%
  );
  background-size: 1000px 640px;

  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
