.body {
  margin: 0 30px;
  // margin-top: 50px;
  @media only screen and (max-width: 768px) {
    margin: 0px;
  }
}

.view-as-dropdown {
  text-align: right;
  font-size: 15px;
  line-height: 15px;
  text-decoration: none;
  color: #4A9876;
  margin-right: 30px;

  @media only screen and (max-width: 1071px) {
    display: none;
  }
}
