.leaderboard-table {
  border-collapse: collapse;
  width: 100%;
  th {
    color: black;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
    border: none;
    &:first-child {
      width: 20px;
    }
    &:nth-child(3) {
      width: 50px;
      text-align: right;
    }
  }
  tr {
    border-bottom: 1px solid #e5e5e5;
    &:nth-child(2) {
      background-color: #e0f5f3;
      color: #047c6e;
      border-bottom: 10px solid #fff;
      td {
        &:first-child {
          font-weight: 500;
        }
      }
    }
    &:nth-child(3) {
      background-color: #f8f1e7;
      color: #e79208;
      border-bottom: 10px solid #fff;
      td {
        &:first-child {
          font-weight: 500;
        }
      }
    }
    &:nth-child(4) {
      background-color: #ebfafe;
      color: #1878f2;
      border-bottom: 10px solid #fff;
      td {
        &:first-child &:nth-child(3) {
          font-weight: 500;
        }
      }
    }
  }
}
