.analytics {
  &_coming-soon {
    height: 65vh;
    margin-left: 25%;

    @media only screen and (max-width: 768px) {
      margin-left: 10%;
      width: 500px;
    }

    @media only screen and (max-width: 500px) {
      margin-left: 0%;
      width: 280px;
    }
  }
}
