$color-grey: #586874;
$color-green: #119797;
$color-red: #f36d6d;
$color-blue: #0058db;

.activityLog {
  padding: 15px;
  box-shadow: 0px 0px 4px rgba(13, 13, 13, 0.2);
  border-radius: 8px;
  height: 100%; /* Ensure it takes full height */
  overflow: hidden;
  // height: 82vh;
  width: auto;
  // margin-left: 453.33px;
  // overflow-y: scroll;

  &__header {
    display: flex;
    position: relative;
    // border-bottom: 1px solid #f3f3f6;
  }

  &__button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    // float: right;
    position: absolute;
    width: 90px;
    // right:'2%';

    &:focus {
      outline: none;
      color: #4a9876;
      border-bottom: 2px solid #4a9876;
    }
  }

  &__button-1 {
    border: none;
    background-color: transparent;
    color: white;
    cursor: pointer;

    &:focus {
      outline: none;
      color: #4a9876;
      border-bottom: 2px solid #4a9876;
    }
  }

  &__menu {
    // margin-top: 20px;
    // margin-left: 55%;
    position: absolute;
    right: 21%;
  }

  &__date {
    // text-decoration: line-through;
    // text-decoration-color: #f3f3f6;
    // text-decoration-skip: edges;
    font-size: 12px;
    border-bottom: 1px solid #f3f3f6;
    margin: 0;
  }
  &__pinnedButton {
    position: absolute;
    top: -3px;
    right: -1px;
    z-index: 100;

    button {
      border: none;
      outline: none;
      background: #fff1d6;
      border-radius: 50%;
      padding: 2px 4px;
      color: #fdd14f;
    }
  }

  &__activity {
    margin-bottom: 10px;
    margin-right: 5px;

    &-desc {
      position: relative;
      border: 1px solid #dee3ed;
      box-sizing: border-box;
      border-radius: 14px;
      padding: 5px;
      margin-bottom: 14px;
      overflow: hidden;
    }

    &-date {
      color: $color-grey;
      font-size: 12px;
      display: block;
    }

    &-task {
      font-size: 12px;
      color: $color-grey;
      margin: 0;
      margin-bottom: 3px;
      font-weight: 300;
    }

    &-name {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #586874;
      // margin-bottom: 6px;
      font-style: normal;
    }

    &-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 2px;
      color: #333333;
      font-style: normal;
    }

    &-description {
      font-size: 13px;
      line-height: 14px;
      color: #4d4d4d;
      font-weight: normal;
      font-style: normal;
      // margin-bottom: 15px;
      width: 24vw;

      @media only screen and (max-width: 1070px) {
        width: 37vw;
      }
      @media only screen and (max-width: 900px) {
        width: 35vw;
      }
      @media only screen and (max-width: 768px) {
        width: 87vw;
      }
      @media only screen and (max-width: 620px) {
        width: 80vw;
      }
      @media only screen and (max-width: 440px) {
        width: 75vw;
      }
      @media only screen and (max-width: 360px) {
        width: 70vw;
      }
    }
    &-replyButton {
      font-size: 12px;
      line-height: 14px;
      color: #000;
      border: none;
      background: transparent;
      cursor: pointer;
      flex: 50%;
      text-align: right;
      outline: none;
      text-decoration: underline;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
        color: #4a9876;
      }
    }

    &-time {
      font-size: 12px;
      margin-top: 5px;
      margin-bottom: 3px;
      color: $color-grey;
    }
  }
}
.activityLog__list {
  /* Remove default List padding if any */
  padding: 0 !important;
}
.activity-area {
  margin-bottom: 20px;
  position: relative;
}

.a-phone {
  color: #38a476;
  background-color: #e8f3ee;
  padding: 2px 4px;
  border-radius: 50%;
}

.a-email {
  color: #38a476;
  background-color: #e8f3ee;
  padding: 2px;
  border-radius: 50%;
}

.a-type {
  position: absolute;
  top: 8%;
  margin-left: 8px;
  margin-right: 10px;
  padding: 6px;
  border-radius: 8px;
  font-size: 14px;
  //   transform: translateY(-15px);
}

.a-1 {
  margin-bottom: 10px;
}
