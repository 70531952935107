.onboarding-video {
  height: 85vh;
  margin: 0;
  // overflow: hidden;
  &-grid {
    display: grid;
    grid-template-columns: 35% 65%;
    @media only screen and (max-width: 920px) {
      display: block;
    }
  }
  &-header-grid {
    display: grid;
    width: 100%;
    grid-template-columns: 85% 10%;
    @media only screen and (max-width: 920px) {
      grid-template-columns: 8% 57% 30%;
    }
  }
  &-tour-close-button {
    font-size: 14px;
    color: white;
    background-color: #41c99d;
    border: none;
    outline: none;
    cursor: pointer;
    height: 26px;
    padding:20px !important;
    border-radius: 3px;
    transform: translateY(20px);
    width: 95%;
    @media only screen and (max-width: 920px) {
      width: 100%;
    }
  }
  &-skip-button {
    display: block;
    border: none;
    font-size: 16px;
    color: white;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    text-decoration: none;
    margin: 0 auto;
    margin-top: 10px;
    font-weight: 500;
    padding:15px;
    background-color: #4A9876 !important ;
    border-radius: 4px;
  }
  &-sidebar {
    height: 74vh;
    overflow-y: auto;
    width: 35vw;
    border-right: 1px solid #e5e5e7;
    overflow-x: hidden;
    background-color: #F9FAFB;
    color: black;

    &-display {
      display: none;
      @media only screen and (max-width: 920px) {
        display: block !important;
        width: 300px;
        height: 95vh !important;
      }
    }

    &-mobile {
      display: none;
      @media only screen and (max-width: 920px) {
        display: block;
        position: fixed;
        top: 50px;
        left: 0;
        z-index: 100;
        width: 200px;
      }

      &-button {
        display: none;
        @media only screen and (max-width: 920px) {
          display: block;
          background-color: transparent;
          outline: none;
          border: none;
          font-size: 20px;
          transform: translateY(5px);
        }
      }
    }

    &-list {
      font-size: 16px;
      margin: 10px;

      &-item {
        margin: 10px 0;
        margin-left: 20px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
          color: #41c99d;
        }
      }
    }
    @media only screen and (max-width: 920px) {
      display: none;
    }
  }
  &-player {
    margin: auto;
    &-title {
      font-size: 20px;
      color: #4A9876 ;
    }
  }
}

.mobile-only {
  display: none;
  margin-bottom: 35px;
  text-align: right;
  @media only screen and (max-width: 920px) {
    display: block !important;
  }
}
