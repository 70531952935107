$color-blue-dark: #2b63d4;
$color-blue-med: #005ce5;
$color-blue-light: #517ef1;
$color-purple: #8585d1;

@import url("https://fonts.googleapis.com/css?family=Muli&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,500&display=swap");

.form-control.success input {
  border-color: #2ecc71;
}

.form-control.error input {
  border-color: #e74c3c;
}

.form-control i {
  visibility: hidden;
  position: absolute;
  top: 40px;
  right: 10px;
}

.form-control.success i.fa-check-circle {
  color: #2ecc71;
  visibility: visible;
}

.form-control.error i.fa-exclamation-circle {
  color: #e74c3c;
  visibility: visible;
}

.form-control small {
  color: #e74c3c;
  position: absolute;
  bottom: 0;
  left: 0;
  visibility: hidden;
}

.form-control.error small {
  visibility: visible;
}

.signin {
  // font-size: 13px;
  &__left {
    position: relative;
    height: 100vh;
  }
  &__form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__heading {
    display: block;
    font-size: 25px;
    text-align: center;
    font-weight: bold;
    line-height: 22px;
    font-family: "Poppins", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  }
  &__button {
    display: block;
    text-align: center;
    border: 2px solid #4A9876;
    text-decoration: none;
    font-size: 16px;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
    background-color: #4A9876;
    // margin: 20px 0;
    color: white;
    outline: none;
    transition: all 0.5s;
    width: 360px;
    // height: 37.55px;
    text-align: center;
    border-radius: 7px;
    background: #517ef1;
    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    &:active {
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    }
  }
  &__button-1 {
    display: block;
    text-align: center;
    width: 133.98px;
    height: 37.55px;
    border: 2px solid #252a2d;
    text-decoration: none;
    text-transform: none !important;
    outline: none;
    font-size: 16px;
    padding: 5px;
    background-color: #252a2d !important;
    margin: 20px 0;
    color: white !important;
    transition: all 0.5s;
    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.55);
    }
    &:active {
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    }
  }
  &__label {
    // margin-top: 8px;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 10px;
    color: #586874;
    font-weight: normal;
  }
  &__input {
    margin-bottom: 2px;
    display: block;
    height: 37.55px;
    width: 360px;
    // margin-bottom: 10px;
    border: 1px solid #c5c5d3;
    box-sizing: border-box;
    border-radius: 7px;
    outline: none;
    padding-left: 10px;
    
  }
  &__question {
    position: absolute;
    bottom: 2%;
    left: 40%;
    font-size: 15px;
    line-height: 18px;
  }
  &__link {
    text-decoration: none;
    color: $color-blue-med;
  }
  &__validators {
    font-size: 8px;
    color: #c5c5d3;
    margin-bottom: 3px;
    ul {
      display: flex;
      flex-direction: column;
    }
  }
}
.grad {
  background-color: #252a2d;
  height: 100vh;
  width: 48.86vw;
  position: relative;
}
.OR {
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  color: #586874;
  margin: 18px auto;
}

.forgot-password {
  text-decoration: none;
  color: #4A9876;
  display:block;
  margin-top:20px;

  &:hover {
    text-decoration: underline;
  }
}
.showpassword-button{
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  margin-left: -34px;
  z-index: 999;

  &:hover{
    color: #4A9876;
  }
}
