@import "Variables";

$color-red: #f36d6d;
$color-sky: #d3f1f4;
$color-grey: #abb7bf;
$color-green: #119797;

.customerInfo {
  font-size: 12px;
  font-weight: 300;
  padding-left: 15px;
  padding-right: 15px;
  // position: fixed;

  input[type="text"] {
    border: 1px solid #c5c5d3;
    box-sizing: border-box;
    border-radius: 7px;
    width: 70%;
    height: 24px;
    padding: 5px;
    margin-bottom: 10px;
  }
  input[type="tel"] {
    border: 1px solid #c5c5d3;
    box-sizing: border-box;
    border-radius: 7px;
    width: 70%;
    height: 24px;
    padding: 5px;
    margin-bottom: 10px;
  }

  input[type="email"] {
    border: 1px solid #c5c5d3;
    box-sizing: border-box;
    border-radius: 7px;
    width: 70%;
    height: 24px;
    padding: 5px;
    margin-bottom: 10px;
  }

  input[type="number"] {
    border: 1px solid #c5c5d3;
    box-sizing: border-box;
    border-radius: 7px;
    width: 70%;
    height: 24px;
    padding: 5px;
    margin-bottom: 10px;
  }

  input[type="date"] {
    border: 1px solid #c5c5d3;
    box-sizing: border-box;
    border-radius: 7px;
    width: 70%;
    height: 24px;
    padding: 5px;
    margin-bottom: 10px;
  }

  input[type="time"] {
    border: 1px solid #c5c5d3;
    box-sizing: border-box;
    border-radius: 7px;
    width: 70%;
    height: 24px;
    padding: 5px;
    margin-bottom: 10px;
  }

  textarea {
    border: 1px solid #c5c5d3;
    box-sizing: border-box;
    border-radius: 7px;
    width: 70%;
    height: 40px;
    padding: 5px;
    margin-bottom: 10px;
  }

  select {
    border: 1px solid #c5c5d3;
    box-sizing: border-box;
    border-radius: 7px;
    width: 70%;
    height: 30px;
    padding: 5px;
    margin-bottom: 10px;
  }

  &__Intro {
    box-shadow: 0px 0px 4px rgba(13, 13, 13, 0.2);
    border-radius: 8px;
    padding: 16px;
    padding-top: 7px;
    width: 90%;
    margin-top: 0px;
    margin-bottom: 5px;
    position: relative;

    &:hover {
      .account-editButton-pencil {
        display: block;
      }
    }
  }

  &__opportunity {
    margin-top: 2px;
    position: relative;
  }

  &-icon {
    margin: 0;
    display: inline-block;
    color: #fdd14f;
    background: #fff1d6;
    width: 40px;
    height: 40px;
    text-align: center;
    transform: translateY(-15px);
    border-radius: 50%;
  }
  &-icon-modal {
    margin: 0;
    display: inline-block;
    color: $color-green-800;
    background: $color-green-200;
    width: 20px;
    height: 20px;
    text-align: center;
    transform: translateY(5px);
    border-radius: 50%;
  }
  &-icon-report {
    margin: 0;
    display: inline-block;
    color: #fdd14f;
    background: #ddfce6;
    width: 38.41px;
    height: 38.41px;
    text-align: center;
    border-radius: 10px;
    margin-right: 10px;
  }

  &-info {
    display: inline-block;
    padding-left: 5px;
    margin-bottom: 10px;
  }

  &-desc {
    margin-top: 0;
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }

  &-email {
    text-decoration: none;
    color: white;
    background-color: #4a9876;
    margin-right: 12px;
    font-size: 13px;
    line-height: 16px;
    padding: 4px 11px;
    margin-bottom: 3px;
    border-radius: 11px;
    font-weight: 500;
    // font-size: 15px;
    // line-height: 18px;
    // margin-top: 20px;
  }

  &-call {
    text-decoration: none;
    color: white;
    background-color: #4a9876;
    padding: 9px 18px;
    margin-bottom: 10px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    // margin-top: 20px;
  }

  &__Info {
    // margin-bottom: 10px;
    // padding-bottom: 15px;
    // height: 268.21px;
    margin-bottom: 14px;
  }

  &__AddInfo {
    &-icon {
      margin-top: 6px;
      margin-bottom: 12px;
    }
  }
}

.c-name {
  font-weight: 500;
  font-size: 19px;
  line-height: 21px;
  margin-top: 0;
  margin-bottom: 2px;

  &-input {
    border: none !important;
    border-bottom: 1px solid black !important;
    border-radius: 0 !important;
    font-weight: 500 !important;
    font-size: 19px !important;
    outline: none !important;
    &:focus {
      border-bottom: 1px solid #4a9876 !important;
    }
  }
}

.c-type {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
  margin-bottom: 1.19px;
}

.c-title-main {
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  margin-top: 4px;
  letter-spacing: 0.01em;
  color: #333333;
}

.c-title-main-1 {
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  margin: 0;
  margin-bottom: 10px;
}

.c-title-act {
  color: #586874;
  padding: 0;
  margin: 0;
  margin-bottom: 4px;
  font-size: 13px;
  line-height: 14px;
  font-weight: 500;
}

.c-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
}

.c-pic-in {
  border-radius: 50%;
}

.c-pic-portion {
  display: inline-block;
}

.c-desc {
  font-size: 15px;
  line-height: 18px;
  margin: 0;
  margin-top: 0;
  font-weight: normal;
  margin-bottom: 8px;
  border-bottom: 1px solid #f3f3f6;
  min-height: 10px;
}

.c-desc-1 {
  margin-top: 2px;
  margin-bottom: 1px;
}
.c-stageOval {
  font-size: 15px;
  line-height: 18px;
  margin: 0;
  margin-top: 0;
  font-weight: normal;
  margin-bottom: 8px;
  background: #eef6f2;
  width: 60px;
  padding: 2px 4px;
  border-radius: 4px;
  border-bottom: 1px solid #f3f3f6;
}

.c-icon-1 {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding: 2px 11px;
  color: white;
  background-color: #4a9876;
  margin-right: 20px;
  border-radius: 7px;
}

.c-icon-2 {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding: 2px 11px;
  color: white;
  background-color: #4a9876;
  border-radius: 9px;
}

.c-icon-3 {
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  padding: 0px !important;
  height: 19px !important;
  border-radius: 4px !important;
  color: #cd8003;
  background-color: #fceecf;
  width: auto !important;
  margin-bottom: 5px !important;
  outline: none !important;
  margin-left: 5px;
  border: none !important;
}
.c-icon-won {
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  padding: 0px !important;
  height: 19px !important;
  border-radius: 4px !important;
  color: #4a8566;
  background-color: #ecfccf;
  width: auto !important;
  margin-bottom: 5px !important;
  outline: none !important;
  margin-left: 5px;
  border: none !important;
}
.c-icon-lost {
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  padding: 0px !important;
  height: 19px !important;
  border-radius: 4px !important;
  color: #db3535;
  background-color: #ffdcdc;
  width: auto !important;
  margin-bottom: 5px !important;
  outline: none !important;
  margin-left: 5px;
  border: none !important;
}
.c-icon-4 {
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  padding: 2px 6px !important;
  border-radius: 4px !important;
  color: #4a8566;
  background-color: #ecfccf;
  width: auto !important;
  margin-bottom: 5px !important;
  outline: none !important;
  margin-left: 5px;
  margin-top: 0;
  border: none !important;
  display: inline-block;
}
.c-icon-5 {
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  padding: 2px 6px !important;
  border-radius: 4px !important;
  color: #db3535;
  background-color: #ffdcdc;
  width: auto !important;
  margin-bottom: 5px !important;
  outline: none !important;
  margin-left: 5px;
  margin-top: 0;
  border: none !important;
  display: inline-block;
}

.c-social {
  margin-top: 0;
  text-decoration: none;
  font-size: 11px;
  margin-bottom: 2px;
  color: $color-green;
  margin-top: 0;
}

.c-select {
  background-color: #f3f3f6;
  border-radius: 4px;
  padding: 4px;
  font-size: 12px;
}

.c-opportunityAdd {
  position: absolute;
  right: 2%;
  color: white;
  width: 145.13px;
  height: 24px;
  font-size: 13px;
  line-height: 14px;
  margin-left: 30%;
  transform: translateY(-3px);
  padding: 4px 13px;
  // border: 1px solid #B1BECD;
  box-sizing: border-box;
  border-radius: 15px;
  transition: all 0.4s;
  text-decoration: none;
  background-color: #61c99d;

  &:hover {
    transform: translateY(-6px);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &:active {
    transform: translateY(-4px);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  }
}

.collapseHeader {
  padding: 10px;
  // border: 1px solid black;
  box-shadow: 0px 0px 4px rgba(13, 13, 13, 0.2);
  width: 90%;
  margin-bottom: 10px;
  border-radius: 10px;
  position: relative;
}
.collapseRoleHeader {
  padding: 8px 2px 2px 2px;
  background-color: #f5f5f5;
  width: 98%;
  margin-bottom: 10px;
  position: relative;
  box-shadow: 0px 0px 4px rgba(13, 13, 13, 0.2);
  border-radius: 8px;

  // cursor: ;
}
.collapseRoleHeader__contentInner {
  background-color: white;
  padding-left: 10px;
}
.collapseRoleHeader__contentOuter {
  background-color: white;
}

.collapseHeaderAccount {
  padding: 16px;
  background-color: #ffffff;
  width: 90%;
  margin-bottom: 10px;
  position: relative;
  box-shadow: 0px 0px 4px rgba(13, 13, 13, 0.2);
  border-radius: 8px;
  margin-top: 10px;
  cursor: pointer;
  &__contentInner {
    margin-top: 10px;
    cursor: default;
  }
}
.collapseHeaderAccount-deal {
  padding: 16px 8px;
  background-color: #ffffff;
  width: 94%;
  margin-bottom: 10px;
  position: relative;
  box-shadow: 0px 0px 4px rgba(13, 13, 13, 0.2);
  border-radius: 8px;
  margin-top: 10px;
  cursor: pointer;
  &__contentInner {
    margin-top: 10px;
    cursor: default;
  }
}

.collapseHeaderAccount-edit {
  padding: 16px;
  background-color: #ffffff;
  width: 90.5%;
  margin-left: 2px;
  margin-bottom: 10px;
  position: relative;
  box-shadow: 0px 0px 4px rgba(13, 13, 13, 0.2);
  border-radius: 8px;
  margin-top: 10px;
  &__contentInner {
    margin-top: 10px;
  }
}

.customerIntroStateChange {
  position: fixed;
  height: auto;
  background-color: rgba(#252a2d, 0.9);
  width: 16vw;
  padding: 4px 30px;
  left: 0;
  bottom: 0;
  z-index: 10000;
  border-radius: 0 5px 0 0;
  @media only screen and (max-width: 780px) {
    position: absolute;
    width: 260px;
  }

  p {
    font-weight: 500;
    color: white;
    margin: 2px 0 8px 0;
  }

  &-save {
    padding: 2px 17px;
    background: linear-gradient(0deg, #61c99d, #61c99d);
    width: 80.22px;
    height: 35px;
  }
}
.customerIntro-scorecard {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  &-container {
    border: 1px solid #e5e7eb;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 16px 12px;
    flex: 1;
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
  &-label {
    font-size: 12px;
    font-weight: 500;
    color: #586874;
  }
  &-value {
    font-weight: 700;
    font-size: 18px;
    color: #252a2d;
    margin-top: 5px;
  }
}
.customerIntro-inputField {
  border: none !important;
  outline: none !important;
  font-size: 15px;
  line-height: 18px;
  margin: 0;
  margin-top: 0;
  font-weight: normal;
  margin-bottom: 8px;
  border-bottom: 1px solid #4a9876 !important;
  border-radius: 0 !important;
  width: 100% !important;
}
.c-hoverDesc-button {
  visibility: hidden;
  float: right;
  outline: none;
  border: none;
  background: #f7f7f8;
  outline: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  height: 22px;
  padding: 3px 5px 5px 5px;
  transform: translateY(-3px);
}
.c-hoverDesc-button-close {
  height: 20px;
  margin-left: 5px;
  background-color: transparent;
  color: white;
  border: 1px solid black;
  cursor: pointer;
  border-radius: 50%;
  padding: 0px 5px;
  outline: none;
}
.c-hoverDesc-button-save {
  height: 23px;
  margin-left: 5px;
  background-color: transparent;
  outline: none;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  padding: 0;
}
.c-hoverDesc-div:hover > .c-hoverDesc {
  height: 19px;
  border-bottom: 1px solid #4a9876;
}
.c-hoverDesc-div:hover > .c-hoverDesc > .c-hoverDesc-button {
  visibility: visible;
}

.account-Logactivity-button {
  padding: 8px 10px;
  background: #f4f6f5;
  border-radius: 8px;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  box-shadow: 0px 0px 4px rgba(13, 13, 13, 0.2);
  margin-right: 15px;
  height: 20px;
  width: 16px;
  transform: translateY(3px);
  display: none;

  @media only screen and (max-width: 780px) {
    display: inline-block;
  }
}

.account-sourceButton {
  border-radius: 50%;
  border: 2.5px solid #4a9876;
  color: #4a9876;
  font-weight: 700;
  font-size: 14px;
  padding: 0 4px;
  background-color: white;
  visibility: hidden;
  cursor: pointer;
}
.c-hoverDesc-div:hover .account-sourceButton {
  visibility: visible;
}

.customer-shortcut {
  border-top: 1px solid #ebecef;
  padding-top: 10px;
  margin-top: 14px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  &-container {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 315px 15px;
  }
  &-label {
    font-weight: 500;
    font-size: 14px;
    height: 100%;
    color: #7b828e;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    padding-top: 5px;
    svg {
      margin-bottom: 5px;
    }
    &:not(:first-child) {
      margin-left: 17%;
    }
    &:hover {
      background-color: #f6f9f8;
    }
  }
  &-label-active {
    font-weight: 500;
    font-size: 14px;
    height: 100%;
    color: #4a9876;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5px;
    svg {
      margin-bottom: 5px;
    }
    &:not(:first-child) {
      margin-left: 17%;
    }
    &:hover {
      background-color: #f6f9f8;
    }
  }
  &-add {
    color: #4a9876;
    text-decoration: none;
    font-size: 25px;
    // transform: translate(4px, 11px);
  }
}
.horizontal-menu {
  height: 40px;
}
.menu-item {
  padding: 0 10px;
  // margin: 5px 10px;
  user-select: none;
  cursor: pointer;
  border: none;
}
.menu-item-wrapper.active {
  border: none;
}
.menu-item.active {
  border: none;
}

.scroll-menu-arrow {
  padding: 8px;
  cursor: pointer;
}

.account-editButton-pencil {
  border: none;
  background: #f7f7f8;
  outline: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  position: absolute;
  top: 1.5%;
  right: 0.8%;
  display: none;
}

.integration-screen {
  margin: auto 30px;

  @media only screen and (max-width: 600px) {
    margin: auto 0;
  }
}
.accountDeleteButton {
  background: transparent;
  border: none;
  outline: none;
  position: absolute;
  bottom: 5%;
  right: 1px;
  cursor: pointer;
}

.accountsActionButtonDiv {
  display: flex;
  // justify-content: space-between;
  width: 100%;
  &-buttons {
    cursor: pointer;
    font-weight: 500 !important;
    padding: 6px 8px;
    background-color: transparent;
    border: none;
    outline: none;
    margin-right: 10%;
    width: 80px;
    border-radius: 4px;
    margin-bottom: 8px;
    &:last-child {
      margin-right: 0;
    }
    div {
      font-weight: 600;
      margin-top: 5px;
      color: rgb(88, 104, 116);
    }
    &:hover {
      background-color: #f4f6f5;
    }
  }
}
