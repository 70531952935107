.youtube-video {
  height: 100vh;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(#000000, 0.8);
  z-index: 47483647;

  &-player{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
