.sequence {
  // text-align: center;
  border-right: 1px solid #e5e5e5;
  min-height: 80vh;
  &-heading {
    margin: 10px 0 20px;
    font-weight: 500;
    font-size: 19px;
    text-align: left;
  }
  &-handle {
    position: absolute;
    left: 2%;
    top: 50%;
    transform: translate(0, -50%);
    cursor: grabbing;
  }
  &-steps {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    display: block;
    padding: 2%;
    position: relative;
    width: 40vw;
    margin: 5px auto;
    display: flex;
    align-items: center;
    justify-content: center;

    &-flow {
      border-left: 2px solid #e5e5e5;
      height: 20px;
      margin: 0 auto;
      width: 2px;
    }
  }
  &-steps-2 {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    display: block;
    padding: 4%;
    position: relative;
    width: 40vw;
    margin: 5px auto;
    // display: flex;
    align-items: center;
    justify-content: center;

    &-flow {
      border-left: 2px solid #e5e5e5;
      height: 20px;
      margin: 0 auto;
      width: 2px;
    }
  }

  &-button {
    background-color: #4A9876;
    border: none;
    outline: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    padding: 5px 10px;
    margin: 5px auto;
    display: block;
  }
  &-button-remove {
    background-color: transparent;
    border: none;
    outline: none;
    border-radius: 50%;
    color: #4A9876;
    font-size: 14px;
    position: absolute;
    right: 0;
    top: 1%;
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;
  }
}
