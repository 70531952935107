// Greens
$color-aquamarine: #4A9876;
$color-green-100: #dbf3e8;
$color-green-200: #bfe9d7;
$color-green-300: #a1dfc5;
$color-green-400: #85d5b3;
$color-green-500: #6fd2a8;
$color-green-600: #4A9876;
$color-green-700: #4A9876;
$color-green-800: #3aab7b;
$color-green-900: #199661;
$color-green-1000: #00302b;
$color-green-1100: #001f19;

// Greys
$color-grey-100: #ffffff;
$color-grey-150: #f5f5f5;
$color-grey-200: #e3e3e3;
$color-grey-300: #a7abaa;
$color-grey-400: #6f7272;
$color-grey-500: #383a39;
$color-grey-600: #0c0d0d;

// Blues
$color-blue-100: #ebfaff;
$color-blue-200: #a7e4fa;
$color-blue-300: #1778f2;

// Yellows
$color-yellow-100: #fff1d6;
$color-yellow-200: #ffbc4f;
$color-yellow-300: #e79209;

// Reds
$color-red-100: #f9e1e1;
$color-red-200: #ffc8c8;
$color-red-300: #d32f2f;
