.email-analytics {
  padding: 10px 40px;

  &-heading {
    font-size: 22px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &-grid {
    display: grid;
    grid-template-columns: 14% 14% 14% 14% 14% 14% 14%;
  }
  &-metrics {
    margin-right: 20px;
    margin-bottom: 15px;
    box-shadow: 0px 0px 4px rgba(13, 13, 13, 0.2);
    border-radius: 10px;
    background: #ffffff;
    position: relative;
    padding: 16px 10px;
    display: grid;
    grid-template-columns: 30% 70%;
    &:hover {
      cursor: pointer;
      color: #4A9876;
    }
    img {
      margin: 10px auto;
      height: 20px;
    }
  }
  &-info {
    font-weight: 500;
    text-transform: capitalize;
    font-size: 15px;
  }
  &-infoname {
    font-weight: 700;
    text-transform: capitalize;
    font-size: 17px;
  }
  &-amount {
    font-weight: 700;
    font-size: 19px;
  }
  &-range {
    margin: 0 20px 0 30px;
    padding: 3px 5px;
    &:hover {
      background-color: #f5f5f5;
    }
  }
  &-filters {
    margin: 20px 0;
    display: flex;

    h3 {
      font-size: 19px;
      font-weight: 500;
      margin: 0;
      margin-right: 15px;
    }
  }
}
