.button {
  &-transparent {
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
  }
  &-outline {
    &-green {
      background: white;
      border-radius: 3px;
      padding: 2px 5px;
      color: #61c99d;
      border: 1px solid #61c99d;
      cursor: pointer;
      font-weight: 500;
      outline: none;
    }
    &-red {
      background: white;
      border-radius: 3px;
      padding: 2px 5px;
      color: #d32f2f;
      border: 1px solid #d32f2f;
      cursor: pointer;
      font-weight: 500;
      outline: none;
    }
  }

  &-filled {
    &-green {
      background: #61c99d;
      border-radius: 3px;
      padding: 2px 5px;
      color: white;
      border: 1px solid #61c99d;
      cursor: pointer;
      font-weight: 500;
      outline: none;
    }
  }

  &-no-border {
    &-red {
      background: white;
      border-radius: 3px;
      padding: 2px 5px;
      color: #f47272;
      border: none;
      cursor: pointer;
      font-weight: 500;
      outline: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.button-md-new {
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  outline: none;
  padding: 0px 16px;
  font-weight: 500 !important;
  cursor: pointer;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", "Arial";
  height: 36px;
  font-size: 14px;
  letter-spacing: 0.25px;
  text-align: center;
  &:hover {
    background-color: #e3e3e3 !important;
    color: black;
    // border: 1px solid black;

    svg {
      color: #000 !important;
      stroke: #000 !important;
    }
  }
}

.button-md {
  color: #fff;
  background-color: #212123;
  border: none;
  border-radius: 4px;
  outline: none;
  padding: 0px 16px;
  font-weight: 500 !important;
  cursor: pointer;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", "Arial";
  height: 36px;
  font-size: 14px;
  letter-spacing: 0.25px;
  text-align: center;

  &:hover {
    background-color: #e3e3e3 !important;
    color: black;
    border: 1px solid black;

    svg {
      color: #000 !important;
      stroke: #000 !important;
    }
  }

  &-secondary {
    color: #000;
    background-color: #f5f5f5;
    border: none;
    border-radius: 4px;
    outline: none;
    padding: 0px 16px;
    font-weight: 500 !important;
    cursor: pointer;
    font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", "Arial";
    height: 36px;
    font-size: 14px;
    letter-spacing: 0.25px;
    text-align: center;
    &:hover {
      background-color: #e3e3e3 !important;
      border: 1px solid black !important;
    }
  }

  &-grey-outline {
    color: #000;
    border-radius: 4px;
    outline: none;
    padding: 0px 16px;
    font-weight: 500 !important;
    cursor: pointer;
    font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", "Arial";
    height: 36px;
    font-size: 14px;
    letter-spacing: 0.25px;
    text-align: center;
    border: 1px solid #eef2f7;
    background-color: #fff;
    &:disabled {
      cursor: not-allowed;
      background-color: #e5e5e5;
      color: #a7abaa;
      border: 1px solid #a7abaa;
    }
  }
  &-black-outline {
    color: #000;
    border-radius: 4px;
    outline: none;
    padding: 0px 16px;
    font-weight: 500 !important;
    cursor: pointer;
    font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", "Arial";
    height: 36px;
    font-size: 14px;
    letter-spacing: 0.25px;
    text-align: center;
    border: 1px solid #000;
    background-color: #fff;
    &:hover {
      background-color: #e3e3e3 !important;
    }
    &:disabled {
      cursor: not-allowed;
      background-color: #e5e5e5;
      color: #a7abaa;
      border: 1px solid #a7abaa;
    }
  }
}
.button-outline-black-hover {
  &:hover {
    background-color: #e3e3e3 !important;
  }
}
.button-sm {
  color: #fff;
  background-color: #212123;
  border: none;
  border-radius: 4px;
  outline: none;
  padding: 0px 8px;
  font-weight: 500 !important;
  cursor: pointer;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", "Arial";
  height: 26px;
  font-size: 14px;
  letter-spacing: 0.25px;
  text-align: center;
  &:hover {
    background-color: #e3e3e3 !important;
    color: black;
    border: 1px solid black;

    svg {
      color: #000 !important;
      stroke: #000 !important;
    }
  }

  &-secondary {
    color: #000;
    background-color: #f5f5f5;
    border: none;
    border-radius: 4px;
    outline: none;
    padding: 0px 8px;
    font-weight: 500 !important;
    cursor: pointer;
    font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", "Arial";
    height: 26px;
    font-size: 14px;
    letter-spacing: 0.25px;
    text-align: center;
    &:hover {
      background-color: #e3e3e3;
      border: 1px solid black;
    }
  }
}
