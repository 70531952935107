.tree,
.tree ul,
.tree li {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.tree {
  margin: 0 0 1em;
  text-align: center;
}
.tree,
.tree ul {
  display: table;
}
.tree ul {
  width: 100%;
}
.tree li {
  display: table-cell;
  padding: 0.5em 0;
  vertical-align: top;
}
/* _________ */
.tree li:before {
  outline: solid 1px #666;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.tree li:first-child:before {
  left: 50%;
}
.tree li:last-child:before {
  right: 50%;
}

.tree code,
.tree span {
  border: solid 0.1em #666;
  border-radius: 0.2em;
  display: inline-block;
  margin: 0 0.2em 0.5em;
  padding: 7px 14px;
  position: relative;
  font-weight: 500;
  font-size: 14px;
}
/* If the tree represents DOM structure */
.tree code {
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

/* | */
.tree ul:before,
.tree code:before,
.tree span:before {
  outline: solid 1px #666;
  content: "";
  height: 0.5em;
  left: 50%;
  position: absolute;
}
.tree ul:before {
  top: -0.5em;
}
.tree code:before,
.tree span:before {
  top: -0.55em;
}

/* The root node doesn't connect upwards */
.tree > li {
  margin-top: 0;
}
.tree > li:before,
.tree > li:after,
.tree > li > code:before,
.tree > li > span:before {
  outline: none;
}

/*Switch*/
.general-heading {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: right;
  letter-spacing: -0.03em;
  color: #0c0d0d;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 28px;
  transform: translateY(-3px);
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #3aab7b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3aab7b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 28px;
}

.slider.round:before {
  border-radius: 50%;
}

/* The switch - the box around the slider */
.switch1 {
  position: relative;
  display: inline-block;
  width: 28px; /*52 x 12*/
  height: 16px;
  transform: translateY(-3px);
}

/* Hide default HTML checkbox */
.switch1 input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider1 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #baccdd;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 28px;
}

.slider1:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 1px;
  bottom: 1px;
  background-color: #a5a5a6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  background: #ffffff;
  /* Light/shadow-sm */

  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
}

input:checked + .slider1 {
  background-color: #a1dfc5;
}
input:checked + .slider1:before {
  background-color: #4A9876;
}

input:focus + .slider1 {
  box-shadow: 0 0 1px #a1dfc5;
}

input:checked + .slider1:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(12px);
}

/* Rounded sliders */
.slider1.round1 {
  border-radius: 28px;
}

.slider1.round1:before {
  border-radius: 50%;
}
