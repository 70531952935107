.react-tabs {
  -webkit-tap-highlight-color: transparent;
  // z-index: 47483647;
  // position: fixed;
  // width: 100%;
}

.react-tabs__tab-list {
  // border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
  background: #252a2d;
  z-index: 9999998;
  position: fixed;
  width: 100%;
  display: inline-flex !important;
  max-height: 31px;
  overflow: hidden;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid #3B4043;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  background: #252a2d;
  color: #CFCFD8;
  border-radius: 5px 5px 0 0;
  &:hover{
    background-color: #3b4043;
  }
}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
  &:hover{
    background-color: #fff;
  }
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
  padding-top: 41px;
}

.closeAllTabButton {
  &:hover {
    background-color: #f2f0ef !important;
  }
}