.reactGridItem {
  position: relative;
  display: grid !important;
  grid-auto-rows: 1fr !important;
  grid-template-columns: 1fr !important;
  .deleteButton {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 100;
  }
}
.controlsDashboard {
  text-align: left;
  position: relative;
  display: inline-block;
  input[type="text"],
  select {
    padding: 8px 4px;
    font-size: 15px;
    width: 100%;
    font-weight: 400;
    background-color: #fff;
    height: 18px;

    &:focus,
    &:hover {
      outline: none;
      + label {
        cursor: text;
      }
    }
  }
  select {
    cursor: pointer;
  }
  label {
    position: absolute;
    left: 8px;
    top: 12px;
    color: #999;
    font-size: 14px;
    display: inline-block;
    padding: 4px 10px;
    font-weight: 400;
    pointer-events: none;
    &.activeLabel {
      font-weight: 500;
      top: -11px;
      color: #555;
      background-color: rgba(255, 255, 255, 1);
    }
  }
}
.addComponent {
  &-custom-component {
    padding: 7px 14px;
    list-style: none;
    border-top: 1px solid #f4f6f5;
    margin-bottom: 0;
    &:hover {
      background: rgba(#4A9876, 0.4);
      text-decoration: none !important;
      cursor: pointer;
      h4 {
        color: black;
        text-decoration: none;
      }
      span {
        color: black;
      }
    }

    h4 {
      margin: 0;
      font-weight: 500;
      margin-bottom: 5px;
    }
  }
  &-report {
    padding: 5px 10px;
    &-default {
      cursor: pointer;
      &:hover {
        color: #4A9876;
      }
    }
    &-filter {
      border: none;
      font-size: 15px;
      line-height: 18px;
      margin-right: 10px;
      color: #657885;
      background: transparent;
      outline: none;
      cursor: pointer;
      padding: 5px 12px 5px 12px;
      border-radius: 9px;
      &-selected {
        border: none;
        font-size: 15px;
        line-height: 18px;
        margin-right: 10px;
        color: #4A9876;
        outline: none;
        cursor: pointer;
        background: #f4f6f5;
        padding: 5px 12px 5px 12px;
        border-radius: 9px;
      }
    }
    &-list {
      font-size: 14px;
      height: auto;
      max-height: 380px;
      overflow-y: auto;

      li {
        margin-bottom: 5px;
        &:hover {
          color: #4A9876;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}
// Component/Chart/Chart.css
.highcharts-title {
  font-family: "Roboto", "Arial";
  font-weight: 500;
  color: #000 !important;
  fill: #000 !important;
  font-size: 19px !important;
  &[text-anchor="start"] {
    text-decoration: underline !important;
    cursor: pointer;
  }
}
.highchartsWrapper > div {
  height: 100%;
}
.chart {
  height: 100%;
}
.card {
  div[data-highcharts-chart] {
    max-height: 350px !important;
    padding-top: 50px !important;
  }
}
.card-default-overwrite {
  div[data-highcharts-chart] {
    max-height: 220px !important;
    padding-top: 0px !important;
  }
}
.card-shipment-sales-rep {
  div[data-highcharts-chart] {
    max-height: 320px !important;
    padding-top: 0px !important;
  }
}
// .card {
//   .highcharts-container {
//     height: calc(100% - 40px) !important;
//   }
//   .highcharts-root {
//     height: calc(100% - 40px) !important;
//   }
//   .highcharts-background {
//     height: calc(100% - 40px) !important;
//   }
// }
