.customize-modal {
  &-container {
    padding: 0 30px;
    display: grid;
    grid-template-columns: 38% 62%;
  }

  &-field {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    display: grid;
    grid-template-columns: 20% 60% 20%;
    margin: 5px 10px;
    padding: 4px 8px;
    border-radius: 3px;

    h3 {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
    }
    div {
      font-size: 14px;
    }
    span {
      font-size: 12px;
      text-transform: capitalize;
    }
    input {
      height: 12px;
      width: 12px;
      margin: 2px;
      cursor: pointer;
    }
  }
  input,
  label {
    display: block;
  }

  label {
    font-size: 12px;
  }

  input {
    border: 1px solid #c5c5d3;
    border-radius: 4px;
    padding: 5px;
    width: 95%;
    margin: 5px 0;
  }
  -datepicker {
    width: 100% !important;
  }

  textarea {
    border: 1px solid #c5c5d3;
    border-radius: 4px;
    padding: 5px;
    width: 95%;
    margin: 5px 0;
  }

  select {
    border: 1px solid #c5c5d3;
    border-radius: 4px;
    margin: 5px 0;
    width: 100px;
    padding: 3px;
  }

  .modal-radioButton {
    display: flex;

    button {
      margin-right: 20px;
      border: 1px solid black;
      padding: 5px 10px;
      border-radius: 5px;
    }

    .radio-button {
      display: none;
    }
  }
}
