.webform {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 10px;

  &-options {
    cursor: pointer;
    margin-bottom: 20px;

    &-selected {
      color: #4A9876;
      text-decoration: underline;
    }
  }

  &-preview {
    margin: auto;
    padding: 32px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    width: 86%;
    border-radius: 10px;

    &-heading {
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
      margin-top: 0;
      font-size: 2rem;
    }
    label {
      margin-bottom: 0.5rem;
    }
    input {
      display: block;
      width: 96.8%;
      padding: 0.375rem 0.5rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    select {
      display: block;
      width: 100%;
      padding: 0.375rem 0.95rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    textarea {
      display: block;
      width: 96.8%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.5rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      overflow: auto;
      resize: vertical;
    }
    button {
      display: inline-block;
      font-weight: 400;
      color: #212529;
      text-align: center;
      vertical-align: middle;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-color: transparent;
      border: 1px solid transparent;
      padding: 0.375rem 0.5rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: 0.25rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      width: 100%;
      margin-top: 20px;
    }
  }
}
