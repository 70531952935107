.settings-sidebar {
  width: 220px;
  height: 80vh;
  border-right: 1px solid #e3e3e3;
  position: fixed;
  top: 100px;
  &-heading {
    font-weight: 500;
  }
  &-menu {
    padding: 10px 15px;

    li {
      padding: 5px 0;
    }

    a {
      text-decoration: none;
      color: #a7abaa;
      font-size: 15px;
      line-height: 18px;
      &:hover {
        color: #4A9876;
      }
    }
    i {
      margin-right: 5px;
    }
    &-selected-link {
      color: #4A9876 !important;
      font-weight: 500;
    }
  }
}
