$color-blue: #5c67ff;
$color-grey: #a5a1ac;
$color-grey-light: #f5f7fa;
$color-blue-light: #1f87b2;
$color-green: #119797;
$color-red: #f36d6d;

.tasks {
  border-right: 3px solid #f3f3f6;
  padding: 0 20px;
}

.taskHeading:hover {
  color: #4a9876 !important;
}

.h2 {
  font-weight: 500;
  font-size: 19px;
  line-height: 22px;
  margin-top: 10px;
  margin-bottom: 1px;
  &:hover{
    color: #4a9876 !important;
  }
}

.h3 {
  display: inline-block;
  // letter-spacing: 3px;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  margin-bottom: 14px;
  &:hover{
    color: #4a9876 !important;
  }
}

.icon {
  text-decoration: none;
  font-size: 12px;
  padding: 2px;
  border: 1.5px solid $color-grey;
  border-radius: 2px;
  font-weight: 600;
}

.add {
  border: 2px solid black;
  text-decoration: none;
  font-size: 16px;
  border-radius: 5px;
  padding: 5px;
  background-color: black;
  color: white;
  margin-left: 35px;
  float: right;
  transition: all 0.5s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &:active {
    transform: translateY(-2px);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  }
}

.schedule {
  height: 210px;

  &__items {
    position: relative;
    background-color: #f6f9f8;
    border: 2px solid #f3f3f6;
    height: 54px;
    border-radius: 12px;
    padding: 0px 6px 1px 6px;
    margin-bottom: 3px;

    &:hover {
      background: #e3e3e3;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
    }

    i {
      font-size: 14px;
    }
  }

  &__items-1 {
    position: relative;
    background-color: white;
    border: 2px solid #f3f3f6;
    height: 54px;
    border-radius: 20px;
    padding: 0px 6px 1px 6px;
    margin-bottom: 3px;

    &:hover {
      background: #f6f9f8;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
    }
  }
  &__items-deal {
    position: relative;
    background-color: white;
    border: 2px solid #f3f3f6;
    // height: 80px;
    border-radius: 8px;
    padding: 4px 8px;
    margin-bottom: 3px;

    &:hover {
      background: #f6f9f8;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
    }
    i {
      color: #93939f;
      margin-right: 9px;
    }
    img {
      height: 13px;
      margin-right: 5px;
    }
    .width-100 {
      width: 100% !important;
    }
    &-grid {
      display: grid;
      grid-template-columns: 65% 35%;
      margin-top: 10px;
    }
    &-bar {
      height: 17px;
      width: 6px;
      background-color: #4a9876;
      display: inline-block;
      margin-left: 5px;
    }

    &-name {
      font-size: 15px;
      line-height: 18px;
      font-weight: 500;
      color: #4a9876;
      cursor: pointer;
      border-bottom: 1px solid #f3f3f6;
      padding-bottom: 5px;

      img {
        margin-right: 0 !important;
      }
    }
    &-date {
      color: #5c5c70;
      font-size: 14px;
      line-height: 16px;
    }
    &-revenue {
      color: #5c5c70;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
    }
    &-stages {
      display: flex;
      margin: 5px 0;
      overflow-x: auto;
      padding-bottom: 5px;
    }
    &-stage {
      width: auto;
      height: 20px;
      position: relative;
      background: #a7e4fa;
      color: black;
      padding: 0px 10px;
      padding-right: 5px;
      margin-right: 5px;
      vertical-align: middle;
      text-align: center;
      cursor: pointer;

      div {
        justify-self: center;
        transform: translateY(3px);
      }

      &:after {
        content: "";
        position: absolute;
        left: 0px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 5px solid white;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
      }
      &:before {
        content: "";
        position: absolute;
        right: -5px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 5px solid #a7e4fa;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
      }

      &-selected {
        background: #4a9876;
        color: white;

        &:before {
          border-left: 5px solid #4a9876;
        }
        div {
          justify-self: center;
          transform: translateY(3px);
        }
      }
    }
  }
  &__delete {
    position: absolute;
    border: none;
    background: transparent;
    outline: none;
    right: 4%;
    top: 5%;
    visibility: hidden;
    cursor: pointer;
    z-index: 1000;
  }

  &__heading {
    font-weight: 700;
    margin-bottom: 0px;
    display: inline-table;
  }

  &__desc {
    position: absolute;
    left: 13.5%;
    top: 60%;
    margin: 0 auto;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #5c5c70;
    @media only screen and (max-width: 600px) {
      left: 17.5%;
    }
  }
  &__desc-contact {
    // position: absolute;
    left: 13.5%;
    top: 60%;
    margin: 0 auto;
    margin-left: 13.5%;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #5c5c70;
    @media only screen and (max-width: 600px) {
      left: 17.5%;
      margin-left: 17.5%;
    }
  }

  &__details {
    position: absolute;
    left: 13.5%;
    top: 60%;
    margin: 0 auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    // margin-left: 13%;
    &-sub {
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: #a5a1ac;
    }
  }
  &__date {
    position: absolute;
    right: 6%;
    top: 21%;
    color: #4d4d5e;
    font-weight: normal;
    font-size: 12px;
  }
}
.documentStorage {
  position: relative;
}

.documentStorage:hover .schedule__delete {
  visibility: visible;
}

.name {
  position: absolute;
  left: 13.5%;
  top: 16%;
  border-radius: 3px;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 1px;
}
.name-opp {
  position: absolute;
  left: 14%;
  top: 16%;
  border-radius: 3px;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 1px;
  color: #000000;

  @media only screen and (max-width: 600px) {
    left: 17.5%;
  }
}

.com-1 {
  color: #ffffff;
  //   background-color: #61C99D;
  border-radius: 50%;
  margin-right: 15px;
  padding: 8px 8px 5px 8px;
  display: inline-block;
  transform: translateY(8px) translateX(4px);
  transition: all 0.2s;
  font-size: 14px;
}

.com-2 {
  color: #ffffff;
  background-color: #f9e1e1;
  border-radius: 50%;
  margin-right: 12px;
  padding: 8px 8px 5px 8px;
  display: inline-block;
  transform: translateY(12px) translateX(6px);
  transition: all 0.2s;
  // margin-top: 8px;
}

.com-3 {
  color: aquamarine;
  background-color: lighten(aquamarine, 20%);
  border-radius: 50%;
  margin-right: 15px;
  padding: 5px 7px;
  display: inline-block;
}

.time {
  position: absolute;
  right: 12%;
  top: 60%;
  color: #4d4d5e;
  font-weight: normal;
  padding: 2px 4px;
  font-size: 12px;
  transform: translateX(28px);
  transition: all 0.2s;
}

.phone-icon {
  font-size: 12px;
}

.opportunity-type {
  position: absolute;
  left: 76.5%;
  top: 16%;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  @media only screen and (max-width: 600px) {
    left: 78.5%;
  }
}

.open {
  height: 240px;
  overflow-y: auto;
}

.scheduled {
  height: 240px;
  overflow-y: auto;
}

.FollowupTask {
  margin-top: 20px;
  &-heading {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }
  &-select {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    border: none;
    outline: none;
    cursor: pointer;
    width: 65px;
  }
  &-button {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    background: transparent;
    border: none;
    cursor: pointer;
    color: #000000;
    outline: none;

    &:hover {
      color: #4a9876;
      text-decoration: underline;
    }
  }
}
.coloredTasks {
  &-contact_account_carrier {
    border: 2px solid rgba(99, 91, 255, 0.5);
    margin-bottom: 5px;
  }
  &-overdue {
    border: 2px solid rgba(243, 109, 109, 0.5);
    margin-bottom: 5px;
  }
  &-open {
    border: 2px solid rgba(254, 210, 0, 0.5);
    margin-bottom: 5px;
  }
  &-scheduled {
    border: 2px solid rgba(97, 201, 157, 0.5);
    margin-bottom: 5px;
  }
  &-menu {
    border: none;
    margin-right: 7px;
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 3px;
    color: black;
    background-color: #f5f5f5;
    border: 2px solid #f5f5f5;
    border-radius: 6px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  }
}
