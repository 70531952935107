.accountList {
  padding: 0px 35px;
  @media only screen and (max-width: 800px) {
    padding: 0px;
  }

  &__heading {
    margin-top: 46px;
    font-weight: 500;
    font-size: 19px;
    line-height: 22px;
    color: #333333;
    margin-bottom: 19px;
  }

  &__function {
    margin-bottom: 19px;
  }
  &__table-div {
    max-width: 98vw;
    overflow-x: auto;
    width: 98vw;
  }
  &__table {
    border-collapse: collapse;
    margin: 20px 0;
    th {
      padding: 10px;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      color: #0c0d0d;
      border: 1px solid #e3e3e3;
      border-bottom: 4px solid #e3e3e3;
      background: #ffffff;
      min-width: 140px;

      &:first-child {
        border-radius: 11px 0 0 11px;
      }

      &:last-child {
        border-radius: 0 11px 11px 0;
      }
    }

    tr {
      border-radius: 11px;
      border: 1px solid #e3e3e3;
    }

    td {
      padding: 10px;
      border: 1px solid #e3e3e3;
      font-size: 13px;
      line-height: 150%;
      font-weight: 500;
    }

    a {
      text-decoration: none;
      color: black;
      font-size: 13px;
      line-height: 150%;
      font-weight: 500;
    }

    tr:nth-child(even) {
      background-color: #f3f3f7;
      border-radius: 11px;
    }
  }
  &__tableReport {
    border-collapse: collapse;
    margin: 20px 0;
    th {
      padding: 10px;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      color: #0c0d0d;
      border: 1px solid #e3e3e3;
      border-bottom: 4px solid #e3e3e3;
      background: #ffffff;
      min-width: 140px;

      &:first-child {
        border-radius: 11px 0 0 11px;
        border-right: none;
        max-width: 8px;
        min-width: 8px;
      }
      &:nth-child(2) {
        border-left: none;
      }

      &:last-child {
        border-radius: 0 11px 11px 0;
      }
    }

    tr {
      border: 1px solid #e3e3e3;
    }

    td {
      padding: 10px;
      border: 1px solid #e3e3e3;
      font-size: 13px;
      line-height: 150%;
      font-weight: 500;
      &:first-child {
        border-right: none;
      }
      &:nth-child(2) {
        border-left: none;
      }
    }

    a {
      text-decoration: none;
      color: black;
      font-size: 13px;
      line-height: 150%;
      font-weight: 500;
    }

    tr:nth-child(even) {
      background-color: #f3f3f7;
      border-radius: 11px;
    }
  }
}

.page-item {
  padding: 8px;
}

.page-link {
  color: black;
}

.dealsList-closeDate {
  font-weight: 500;
  margin-left: 35px;
  display: inline-block;

  @media only screen and (max-width: 600px) {
    display: block;
    margin-left: 0;
    margin-top: 10px;
  }
}

.inlineReportEdit {
  button {
    display: none;
    margin: 0 5px;
  }
  &:hover {
    .inlineReportEdit-span {
      display: none;
    }
    .inlineReportEdit-input {
      display: inline-block;
    }
    button {
      display: inline-block;
    }
  }
  &-input {
    display: none;
    font-weight: 500;
    width: 96%;
    padding: 3px;
    border: 1px solid #e3e3e3;

    &:focus {
      display: inline-block;
    }
  }
}

.selectReports {
  display: flex;
  width: 50px;

  &__popoverCustom {
    display: flex;
    padding: 25px;
    flex-direction: column;
  }
}

.numRecInput input {
  padding: 5px !important;
  font-size: 14px;
}

.applyBtn {
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 10px;
}
