.lanes-panel {
  padding: 15px;
  border: 1px solid #4a9876;
  border-radius: 8px;
  min-height: 85vh;
  height: auto;
  margin-bottom: 20px;
  box-shadow: 0 0 0 6px rgba(75, 200, 147, 0.15);
  label {
    color: #586874;
    font-weight: 500;
    font-size: 14px;
  }
  input {
    height: 27px;
    font-size: 15px;
  }
  textarea {
    border: 1px solid #c5c5d3;
    border-radius: 4px;
    padding: 5px;
    width: 95%;
    margin: 5px 0;
  }
  &-form {
    display: flex;
    width: 100%;
    flex: 1;
    flex-wrap: wrap;
    margin-bottom: 8px;
    label {
      color: #586874;
      font-weight: 500;
      font-size: 14px;
    }
    input {
      height: 27px;
      font-size: 15px;
      border: 1px solid #c5c5d3;
      border-radius: 4px;
      padding: 5px;
      width: 95%;
      margin: 5px 0;
    }
    div {
      // width: 100%;
      // width: 250px;
      margin-right: 10px;
      flex: 1;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &-form-1 {
    display: flex;
    width: 100%;
    flex: 1;
    flex-wrap: wrap;
    margin-bottom: 8px;
    label {
      color: #586874;
      font-weight: 500;
      font-size: 14px;
    }
    input {
      height: 27px;
      font-size: 15px;
      width: 95%;
    }
    &-div {
      // width: 100%;
      flex: 1;
      max-width: 300px;
      margin-right: 10px;
      // width: 250px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  input {
    border: 1px solid #c5c5d3;
    border-radius: 4px;
    padding: 5px;
    width: 95%;
    margin: 5px 0;
    &:hover {
      outline: #4a9876;
    }
    &:focus {
      outline: #4a9876;
      border-color: #4a9876 !important;
    }
  }
}
