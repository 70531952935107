.reports {
  margin: 0 40px;
  @media only screen and (max-width: 768px) {
    margin: 0;
  }

  &-heading {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 22px;
    margin-top: 50px;
  }

  &-search {
    background: #f0f2f4;
    border: none;
    border-radius: 7px;
    width: 223px;
    height: 35px;
    padding: 10px;
    outline: none;
    // margin-right: 70%;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      // content: \002B;
      font-family: FontAwesome, Roboto;

      font-weight: normal;
      color: #586874;
      opacity: 1;
      /* Firefox */
    }
  }
  &-tab {
    background: transparent;
    border: 1px solid #e7e7e7;
    color: #4a9876;
    padding: 5px;
    font-size: 15px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 3px;

    &-container {
      margin-top: 20px;
      margin-bottom: 15px;
    }
  }

  &-create-button {
    padding: 2px 17px;
    width: 146.04px;
    height: 35px;
    background: #4a9876;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    outline: none;
    @media only screen and (max-width: 600px) {
      padding: 2px;
    }
    &-positionEdit {
      position: absolute;
      top: 17%;
      right: 10%;
      @media only screen and (max-width: 1280px) {
        right: 16%;
      }
      @media only screen and (max-width: 760px) {
        right: 20%;
      }
    }
  }
  &-edit-button {
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
  &-buttons-container {
    @media only screen and (max-width: 600px) {
      display: flex;
    }
  }
  &-column-button {
    &:hover {
      background-color: #e3e3e3 !important;
      border: 1px solid black !important;
    }
  }
  &-delete-button {
    padding: 2px 17px;
    width: 56.04px;
    height: 35px;
    background: white;
    border: "1px solid #E0EBE6";
    border-radius: 9px;
    color: red;
    cursor: pointer;
    outline: none;
    margin-left: 20px;
  }

  &-filter {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    background-color: #f9fafb;
    border: 2px solid #e7ecf2;
  }

  input {
    width: 192px;
    height: 37px;
    margin-right: 19px;
    padding: 0 10px;
    border: 1px solid #c5c5d3;
    box-sizing: border-box;
    border-radius: 7px;
  }

  select {
    width: 192px;
    height: 37px;
    margin-right: 19px;
    padding: 8px;
    border: 1px solid #c5c5d3;
    box-sizing: border-box;
    border-radius: 7px;
  }
  &-inputFields {
    width: 350px;
    input {
      border: 0px !important;
      width: 350px;
    }
  }
}
.inlineReportEdit {
  button {
    display: none;
    margin: 0 5px;
  }
  &:hover {
    .inlineReportEdit-span {
      display: none;
    }
    .inlineReportEdit-input {
      display: inline-block;
    }
    button {
      display: inline-block;
    }
  }
  &-input {
    display: none;
    font-weight: 500;
    width: 96%;
    padding: 3px;
    border: 1px solid #e3e3e3;

    &:focus {
      display: inline-block;
    }
  }
}
.pagination {
  .page-item {
    padding: 4px 8px;
    color: white !important;
    border-radius: 50%;
    margin-right: 10px;
  }

  .page-item-new {
    padding: 3px 5px;
    color: white !important;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 5px;
    font-size: 12px;
  }

  .active {
    border: 2px solid #4a9876;
    color: white;
    background: #4a9876;
    font-weight: 600;
    border-radius: 4px;

    a {
      color: white;
      text-decoration: none;
    }
  }
  .page-link {
    text-decoration: none;
    color: #6f7272;
  }
}

.selectReports {
  display: flex;
  width: 50px;

  &__popoverCustom {
    display: flex;
    padding: 25px;
    flex-direction: column;
  }
}

.numRecInput input {
  padding: 5px !important;
  font-size: 14px;
}

.applyBtn {
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 10px;
}
