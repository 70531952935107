.profilePage {
  margin: 30px auto;
  width: 50vw;
  form {
    position: relative;
    border: 1px solid #ced4da;
    padding: 2rem;
    padding-bottom: 3rem;
    border-radius: 6px;
  }
  h2 {
    margin: 15px 0;
  }
  label {
    padding-bottom: 5px;
  }
  button {
    color: white;
    background: linear-gradient(0deg, #61c99d, #61c99d);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    // position: absolute;
    // bottom: 1px;
    // right: 15%;
    padding: 2px 17px;
    min-width: 80.22px;
    width: auto;
    height: 35px;
    z-index: 1000;
    display: block;
  }

  input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    // background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 10px;
  }
  select {
    display: block;
    width: 103.5%;
    padding: 0.375rem 0.95rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 10px;
  }
}
.userProfile {
  margin: 30px auto;
  width: 56vw;
  &-save-button {
    color: white;
    background-color: #4A9876;
    border: none;
    outline: none;
    border-radius: 2px;
    padding: 7px 14px;
    margin-top: 15px;
    cursor: pointer;
  }
  label {
    color: #6f7272;
    padding-bottom: 5px;
    font-size: 14px;
  }
  input {
    display: block;
    width: 97.5%;
    padding: 0.375rem 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    // background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 10px;
  }
  select {
    display: block;
    width: 103.5%;
    padding: 0.375rem 0.95rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 10px;
  }

  &-heading {
    &-main {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 35px;
    }
    &-sub {
      font-size: 16px;
      font-weight: 500;
    }
  }

  &-profile {
    margin-bottom: 25px;
    &-substitute {
      height: 80px;
      width: 80px;
      border-radius: 50%;
      text-align: center;
      border: 1px solid #a7abaa;
      vertical-align: middle;
      display: flex;
      justify-content: center;
      div {
        margin: auto;
        font-weight: 500;
        font-size: 20px;
      }
    }
    img {
      border-radius: 50%;
    }
    button {
      margin: 10px 0;
      display: block;
    }
  }
}
