// IntegrationPage.scss

.integrations-page {
  margin: 0 auto;
  padding: 24px;

  a {
    text-decoration: none;
  }
}

.integrations-page-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 32px;

  h1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  p {
    color: #666;
    margin-bottom: 24px;
  }
}

.categories {
  display: flex;
  gap: 8px;
  overflow-x: auto;
  padding-bottom: 8px;

  .category-button {
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 14px;
    border: 1px solid #e2e8f0;
    background: white;
    color: #64748b;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      border-color: #4A9876;
      color: #475569;
    }

    &.active {
      background: #4A9876;
      color: white;
      border-color: #4A9876;

      //   &:hover {
      //     background: #2563eb;
      //   }
    }
  }
}

.search-container {
  position: relative;
  width: 300px;

  .search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #94a3b8;
  }

  .search-input {
    width: 80%;
    padding: 8px 12px 8px 36px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 14px;

    &:focus {
      outline: none;
      border-color: #4A9876;
      box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
    }
  }
}

.integrations-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  //   grid-template-columns: 1fr;
  gap: 16px;
}

.integration-card {
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: white;
  transition: border-color 0.2s;

  &:hover {
    border-color: #4A9876;
  }
}

.integration-content {
  padding: 24px;
}

.integration-main {
  display: flex;
  min-height: 68px;
}

.integration-icon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }
}

.integration-info {
  margin-left: 16px;
  flex-grow: 1;
}

.integration-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  h3 {
    font-weight: 500;
    margin-bottom: 4px;
  }
}
.integration-description {
  color: #64748b;
  font-size: 14px;
}

.status-button {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 14px;
  border: 1px solid transparent;
  cursor: pointer;

  .icon {
    margin-right: 8px;
    font-size: 12px;
    border: none;
  }

  &.connected {
    background: #ecfdf5;
    color: #059669 !important;
    border-color: #a7f3d0;

    &:hover {
      background: #d1fae5;
    }
  }

  &.configured {
    background: #eff6ff;
    color: #3b82f6 !important;
    border-color: #bfdbfe;

    &:hover {
      background: #dbeafe;
    }
  }

  &.available {
    background: #f1f5f9;
    color: #64748b !important;
    border: none;

    &:hover {
      background: #e2e8f0;
    }
  }
}

.details-section {
  margin-top: 16px;
  background: #f8fafc;
  padding: 12px;
  border-radius: 6px;

  .detail-row {
    display: flex;
    font-size: 14px;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .detail-label {
    color: #64748b;
    width: 96px;
  }

  .detail-value {
    font-family: monospace;
  }
}

.api-key-section {
  margin-top: 16px;
  background: #f8fafc;
  padding: 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 8px;

  .api-key-label {
    color: #64748b;
    font-size: 14px;
  }

  .api-key-value {
    background: white;
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid #e2e8f0;
    font-size: 14px;
    max-width: 200px;
    overflow: auto;
  }

  .copy-button {
    padding: 4px 8px;
    border: none;
    background: none;
    color: #64748b;
    cursor: pointer;

    &:hover {
      color: #475569;
    }
  }
}

.external-link {
  display: inline-block;
  margin-top: 16px;
  color: #3b82f6;
  font-size: 14px;
  text-decoration: none;

  &:hover {
    color: #2563eb;
    text-decoration: underline;
  }
}

.action-section {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;

  .action-button {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border: 1px solid #bfdbfe;
    border-radius: 6px;
    color: #3b82f6;
    background: white;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.2s;

    .icon {
      margin-right: 8px;
      border: none;
    }

    &:hover {
      border-color: #93c5fd;
      background: #f8fafc;
    }
  }
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    gap: 16px;

    .search-container {
      width: 100%;
    }
  }

  .integrations-grid {
    grid-template-columns: 1fr;
  }
}
