$grid-width: 100%;
$gutter-horizontal: 5px;
$gutter-vertical: 10px;

.row {
  max-width: $grid-width;

  margin: 0 auto;

  &:not(:last-child) {
    margin-bottom: $gutter-vertical;
  }

  [class^="col-"] {
    float: left;

    &:not(:last-child) {
      margin-right: $gutter-horizontal;
      @media only screen and (max-width: 1070px) {
        margin-right: 2px;
      }
      @media only screen and (max-width: 768px) {
        max-width: 768px;
        margin-right: 0;
      }
      @media only screen and (max-width: 500px) {
        max-width: 100vw;
        margin-right: 0;
      }
    }
  }

  @mixin clear-fix {
    &::after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .col-1-of-2 {
    width: calc((100% - #{$gutter-horizontal}) / 2);
  }

  .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal}) / 3);
    @media only screen and (max-width: 1070px) {
      width: calc((100% - #{$gutter-horizontal}) / 2) !important;
    }
    @media only screen and (max-width: 768px) {
      width: 100% !important;
    }
  }

  .col-1-of-4 {
    width: calc((100% - 4 * #{$gutter-horizontal}) / 4);
  }

  .col-1-of-5 {
    width: calc((100% - 5 * #{$gutter-horizontal}) / 5);
  }

  .col-5-of-6 {
    width: calc(
      (5 * (100% - 5 * #{$gutter-horizontal}) / 6) + (4 * #{$gutter-horizontal})
    );
  }

  .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal}) / 6);
  }

  .col-2-of-3 {
    width: calc(
      (2 * (100% - 2 * #{$gutter-horizontal}) / 3) + #{$gutter-horizontal}
    );
  }

  .col-half-of-4 {
    width: calc(
      (0.5 * (100% - 3 * #{$gutter-horizontal}) / 4) + #{$gutter-horizontal}
    );
  }

  .col-2-of-4 {
    width: calc(
      (2 * (100% - 3 * #{$gutter-horizontal}) / 4) + #{$gutter-horizontal}
    );
  }

  .col-3-of-4 {
    width: calc(
      (3 * (100% - 3 * #{$gutter-horizontal}) / 4) + (2 * #{$gutter-horizontal})
    );
  }
}
