.general-heading {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: right;
  letter-spacing: -0.03em;
  color: #0c0d0d;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 28px;
  transform: translateY(-3px);
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #3aab7b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3aab7b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 28px;
}

.slider.round:before {
  border-radius: 50%;
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 24px;
  background-color: transparent;
}

.grid-container > div {
  background-color: #ffffff;
  padding: 20px 16px 20px 24px;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(13, 13, 13, 0.2);
  border-radius: 8px;
}
.grid-container-1 {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 24px;
  background-color: transparent;
}

.grid-container-1 > div {
  background-color: #ffffff;
  padding: 20px 16px 20px 24px;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(13, 13, 13, 0.2);
  border-radius: 8px;
}
.item2 {
  border: 2px solid #3aab7b;
}
.package-heading {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.03em;
  color: #383a39;
}
.package-pricing {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 44px;
  line-height: 150%;
  letter-spacing: -0.03em;
  color: #0c0d0d;
}
.package-heading-sub {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.03em;
  color: #0c0d0d;
}
.try-free-button {
  background: #4A9876;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 300px;
  height: 44px;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  font-weight: 700;
}
.talk-sales-button {
  border: 1px solid #4A9876;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #ffffff;
  color: #4A9876;
  width: 300px;
  height: 44px;
  outline: none;
  cursor: pointer;
  font-weight: 700;
}

@media (max-width: 800px) {
  .grid-container {
    padding: 0px;
    grid-gap: 10px;
  }
  .grid-container > div {
    grid-column-start: 1;
    grid-column-end: 3;
    padding: 20px 5px 20px 34px;
    width: 94vw;
  }
  .modal-content {
    padding: 0%;
    width: 100%;
    height: 100vh;
  }
}
@media (max-width: 600px) {
  .grid-container > div {
    grid-column-start: 1;
    grid-column-end: 3;
    padding: 20px 5px 20px 12px;
    width: auto;
  }
}
